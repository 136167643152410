// @ts-strict-ignore
import React, { type FC } from 'react';
import { useRouter } from 'bb/app/router';
import { DeprecatedLinkButton } from 'bb/common/DeprecatedLinkButton';
import { type Notification } from 'bb/common/types';
import { useTranslation, Text } from 'bb/i18n';
import { useCtaTracker } from 'bb/tracker/hooks/useCtaTracker';
import { Card, Stack, CrossIcon, Display } from 'bb/ui';
import css from './notification.module.scss';

type NotificationProps = {
    notification: Notification;
    onClose: () => void;
    show: boolean;
};

export const FloatingNotification: FC<NotificationProps> = ({
    notification,
    onClose,
    show
}) => {
    const { routes } = useRouter();
    const tracker = useCtaTracker();
    const { t } = useTranslation('common');
    return (
        <Display on={show}>
            <section className={css.floating}>
                <button
                    type="button"
                    className={css.close}
                    onClick={onClose}
                    aria-label={t('common:close')}
                    title={t('common:close')}
                >
                    <CrossIcon size="small" />
                </button>
                <Card padding="small">
                    <Stack alignItems="flexStart">
                        <Text
                            marginBottom="extraSmall"
                            margin="none"
                            size="large"
                            className={css.header}
                            data-optimizely="floating-notification-title"
                        >
                            {notification.title}
                        </Text>
                        <Text
                            marginBottom="small"
                            data-optimizely="floating-notification-text"
                        >
                            {notification.text}
                        </Text>

                        <DeprecatedLinkButton
                            className={css.buttonText}
                            route={
                                notification.link === 'registration'
                                    ? routes.userDetails
                                    : routes.buyGiftcards
                            }
                            fluid
                            onClick={() =>
                                tracker.signupCta(
                                    `startpage-popup-${notification.link}`
                                )
                            }
                            variant="secondary"
                        >
                            {notification.buttonText}
                        </DeprecatedLinkButton>
                    </Stack>
                </Card>
            </section>
        </Display>
    );
};
